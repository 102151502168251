import { Button } from '@paljs/ui/Button';
import { Card, CardBody, CardFooter, CardHeader } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import { InputGroup } from '@paljs/ui/Input';
import Row from '@paljs/ui/Row';
import React, { useState, useEffect } from 'react'
import SelectName from './select-name';
import EditDate from '../../components/date/editDate';
import { partialDetailsApi } from '../../actions/familyTreeAction'
import { getLoggedInUser } from '../../actions/authAction';

const DetailsOfSpouse = (props) => {
    const [name, setName] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState(props.selectedNode?.gender == 'male' ? 'female' : 'male');
    const [loggedInUser, setLoggedInUser] = useState({});


    const { selectedNode, onClose } = props

    useEffect(() => {
        const loggedInUser = getLoggedInUser();
        setLoggedInUser(loggedInUser);
    }, [])

    const onSubmit = async () => {

        const updatedMetadata = {
            ...selectedNode?.metadata,
            spouse: {
                name: name,
                age: age,
                father: fatherName
            }
        }


        const data = {
            logged_in_node: loggedInUser.graph_node_id,
            metadata: updatedMetadata
        }
        console.log('updated node details', data);

        const response = await partialDetailsApi(selectedNode?.uid, data)
    }
    return (
        <>
            <Card>
                <CardHeader>Partial Details of {selectedNode?.name}'s Spouse </CardHeader>
                <CardBody>
                    <Row>
                        {/* <div style={{color :'red', border:'1px solid '}}>Note :-  नाम के आगे श्री/स्व/श्रीमती और अंत में जी, कूमार, कुमारी, देवी, गौत्र, समाज नाम इत्यादि न लगाएँ, केवल मूल नाम लिखें </div> */}
                        <Col style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>

                            <InputGroup style={{ width: '300px', padding: '5px', display: 'block' }} fullWidth>
                                <div>
                                    Name:
                                </div>
                                {/* <input
                                    value={name}
                                    placeholder="name"
                                    onChange={(event) => { setName((event.target.value).trim()) }}
                                /> */}
                                <SelectName setName={setName} gender={gender} name={name} />
                            </InputGroup>


                            {/* </Col>

                        <Col> */}

                            <InputGroup style={{ width: '300px', padding: '5px', display: 'block' }} fullWidth>
                                <div>
                                    Father Name:
                                </div>
                                <SelectName setName={setFatherName} gender={'male'} name={fatherName} />
                            </InputGroup>
                            {/* </Col>

                        <Col> */}
                            <InputGroup style={{ width: '300px', padding: '5px', display: 'block' }} fullWidth>
                                <div>
                                    Age:
                                </div>
                                <input
                                    type="number"
                                    value={age}
                                    onChange={(event) => {
                                        setAge(event.target.value.trim());
                                    }}
                                />
                            </InputGroup>
                        </Col>

                        {/* <Col style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>

                            <div style={{ display: 'block' }}>
                                <EditDate defaultDate = {dateOfBirth} onChange={onBirthChange} />
                            </div>

                        </Col> */}
                        {/* <div style={{ paddingLeft: '60px' }}>Date of Marriage :</div>

                        <Col style={{ display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                            {married && gender == 'male' ? (
                                <div style={{ display: 'block' }}>
                                    <EditDate defaultDate={dateOfMarriage} onChange={onMarriageChange} />
                                </div>
                            ) : null}
                        </Col> */}

                    </Row>

                </CardBody>

                <CardFooter>

                    <Row style={{ display: 'flex', justifyContent: 'center' }}>

                        <Button status="Success" type="button" shape="SemiRound"
                            style={{ margin: '10px', display: 'block' }}
                            onClick={onSubmit}
                        >
                            submit
                        </Button>

                        <Button status="Danger" type="button" shape="SemiRound"
                            style={{ margin: '10px', display: 'block' }}
                            onClick={onClose}
                        >
                            cancel
                        </Button>

                    </Row>

                </CardFooter>
            </Card>
        </>
    )
}

export default DetailsOfSpouse;
